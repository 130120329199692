import React from 'react';
import { Layout, Seo } from 'components';

// import page style
import './why.scss';

const WhyPage = () => {
  return (
    <Layout indication="patient" className="patient-why">
      <Seo pageTitle="Patient Why Page" />
      <div className="body-content-container">
        <div className="body-content gutter-all">
          {/** Actuall content goes here */}
          <h1>Patient Why Page</h1>
        </div>
      </div>
    </Layout>
  );
};

export default WhyPage;
